<template>
  <div>
    <!-- <slider></slider> -->
    <slider-inicio></slider-inicio>
    <!-- <noticias-slide></noticias-slide>  -->
    <transmision-en-vivo></transmision-en-vivo>
    <ultimas-noticias></ultimas-noticias>     
    <justicia-libre></justicia-libre>
    <accesos-principal></accesos-principal>
    <multimedia></multimedia>
    <accesos-secundario></accesos-secundario>
    <accesos-enlaces></accesos-enlaces>    
  </div>
</template>

<script>
// import Slider from '../components/Slider.vue'
import SliderInicio from '../components/SliderInicio.vue'
import TransmisionEnVivo from '../components/TransmisionEnVivo.vue'
// import NoticiasSlide from '../components/NoticiasSlide.vue'

import UltimasNoticias from '../components/UltimasNoticias.vue'
import JusticiaLibre from '../components/JusticiaLibre.vue'
import AccesosPrincipal from '../components/AccesosPrincipal.vue'
import Multimedia from '../components/Multimedia.vue'
import AccesosSecundario from '../components/AccesosSecundario.vue'
import AccesosEnlaces from '../components/AccesosEnlaces.vue'

export default {
  components: {
    // Slider,
    SliderInicio,
    // NoticiasSlide,
    TransmisionEnVivo,
    UltimasNoticias,
    JusticiaLibre,
    AccesosPrincipal,
    Multimedia,
    AccesosSecundario,
    AccesosEnlaces,
  },
  metaInfo: {
    title: 'Fiscalía General del Estado',
    //titleTemplate: '%s | vue-meta Example App',
    htmlAttrs: {
      lang: 'es-ES'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Ministerio Público Fiscalía General del Estado' },
      { name: 'keywords', content: 'FGE, Fiscalía, Bolivia' },
    ]
  }
}
</script>

<style>
  .box-acceso{
    margin-left: 20px;
    margin-right: 20px;
  }
</style>
