<template>
    <div>
      <div id="slider-home" class="carousel slide" data-bs-ride="carousel" v-if="item">
        <div class="carousel-indicators">
          <button v-for="i in item.slideritems.length" type="button" data-bs-target="#slider-home" :data-bs-slide-to="(i-1)" :class="i === 1 ? 'active' : ''" aria-current="true" :aria-label="'Slide ' + (i -1) " :key="(i - 1)"></button>          
        </div>
        <div class="carousel-inner">
          <div v-for="(item, index) in item.slideritems" :key="item.id" class="carousel-item" :class="index == 0 ? 'active' : ''">
            <div v-if="item.isurl_imagen">              
              <a v-if="isExterno(item.url_slider)" target="_blank" :href="item.url_slider">                
                <video v-if="isVideo(item.urlimagen)" playsinline="" autoplay="" loop="" muted="" style="opacity: 1; width: 100%; height: 100%; margin: 0;"><source :src="item.urlimagen" type="video/mp4"><source src="#" type="video/webm"></video>
                <img v-else :src="item.urlimagen" class="bd-placeholder-img" :alt="item.titulo" :title="item.titulo" width="100%" height="100%">                            
              </a>
              <router-link v-else :to="item.url_slider">
                <video v-if="isVideo(item.urlimagen)" playsinline="" autoplay="" loop="" muted="" style="opacity: 1; width: 100%; height: 100%; margin: 0;"><source :src="item.urlimagen" type="video/mp4"><source src="#" type="video/webm"></video>
                <img v-else :src="item.urlimagen" class="bd-placeholder-img" :alt="item.titulo" :title="item.titulo" width="100%" height="100%">                            
              </router-link>              
            </div>
            <div v-else>
              <video v-if="isVideo(item.urlimagen)" playsinline="" autoplay="" loop="" muted="" style="opacity: 1; width: 100%; height: 100%; margin: 0;"><source :src="item.urlimagen" type="video/mp4"><source src="#" type="video/webm"></video>
              <img v-else :src="item.urlimagen" class="bd-placeholder-img" :alt="item.titulo" :title="item.titulo" width="100%" height="100%">                            
            </div>

            <div class="container">
              <div class="carousel-caption text-center">
                <img v-if="item.imagen_miniatura" v-lazy="item.imagen_miniatura" class="logo-slider" height="150">
                
                <div v-if="item.isurl_text_1">
                  <a v-if="isExterno(item.url_slider)" target="_blank" :href="item.url_slider"><h1 v-if="item.texto1" :style="'color:'+ item.color1 +';'">{{ item.texto1 }}</h1></a>
                  <router-link v-else :to="item.url_slider"><h1 v-if="item.texto1" :style="'color:'+ item.color1 +';'">{{ item.texto1 }}</h1></router-link>
                </div>
                <div v-else>
                  <h1 v-if="item.texto1" :style="'color:'+ item.color1 +';'">{{ item.texto1 }}</h1>
                </div>

                <div v-if="item.isurl_text_2">
                  <a v-if="isExterno(item.url_slider)" target="_blank" :href="item.url_slider"><p v-if="item.texto2" :style="'color:'+ item.color2 +';'">{{ item.texto2 }}</p></a>
                  <router-link v-else :to="item.url_slider"><p v-if="item.texto2" :style="'color:'+ item.color2 +';'">{{ item.texto2 }}</p></router-link>
                </div>
                <div v-else>
                  <p class="">{{ item.texto2 }}</p>
                </div>               
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#slider-home" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#slider-home" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios';

export default {
  name: 'SliderInicio',
  components: {
    
  },
  data(){
    return{
        item:{
          slideritems:[]
        },             
    }
  },
  mounted(){          
      this.getSlider();
  },
  methods: {
    getSlider(){
        var url= '/web/slider-home';
        axiosIns.get(url)
        .then(res => {
            this.item = res.data; 

        })
        .catch(err =>{
            console.log(err);
        });
    },
    isExterno(url){
        if(url.indexOf('http') == 0)
            return true;
        else    
            return false;
    },
    isVideo(url){
        if(url.indexOf('mp4') > 0)
            return true;
        else    
            return false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a, a:hover{
    text-decoration: none;
  }
</style>
