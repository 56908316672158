<template>
  <div id="slider-jl" v-if="items">
    <div v-if="items.slideritems.length > 0">
      <carousel :autoplay="true" :nav="false" :responsive="{0:{items:1,nav:false},600:{items:1,nav:false},900:{items:1,nav:false}}">
        <section v-for="item in items.slideritems" :key="item.id" class="sistema bg-gray pd-0" :style="'background: url('+ item.urlimagen +')no-repeat center center / cover'">
          <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-4">
                    <div class="download-img d-flex align-items-end">
                        <img v-lazy="item.imagen_miniatura" alt="download" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7">
                  <div class="text-white pb-100 text-center mt-3">
                      <div v-if="item.isurl_text_1">
                        <a v-if="isExterno(item.url_slider)" target="_blank" :href="item.url_slider"><h1 class="titulo-seccion text-white text-center">{{ item.texto1 }}</h1></a>
                        <router-link v-else :to="item.url_slider"><h1 class="titulo-seccion text-white text-center">{{ item.texto1 }}</h1></router-link>
                      </div>
                      <div v-else>
                        <h1 class="titulo-seccion text-white text-center">{{ item.texto1 }}</h1>
                      </div>

                      <div v-if="item.isurl_text_2">
                        <a v-if="isExterno(item.url_slider)" target="_blank" :href="item.url_slider"><p class="">{{ item.texto2 }}</p></a>
                        <router-link v-else :to="item.url_slider"><p class="">{{ item.texto2 }}</p></router-link>
                      </div>
                      <div v-else>
                        <p class="">{{ item.texto2 }}</p>
                      </div>
                      
                      <div class="text-center" v-if="item.isurl_imagen">
                          <a v-if="isExterno(item.url_slider)" :href="item.url_slider" class="btn btn-md btn-fiscalia-light" target="_blank">Ver Detalles</a>
                          <router-link v-else class="btn btn-md btn-fiscalia-light" :to="item.url_slider">Ver Detalles</router-link>                        
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </carousel>  
    </div>    
  </div>
  
</template>

<script>
import axiosIns from '@/libs/axios';
import carousel from  'vue-owl-carousel';

export default {
  name: 'JusticiaLibre',
  components:{
    carousel,
  },
  data(){
        return{
              items:{
                slideritems:[]
              },
              
        }
  },
  mounted(){          
      this.getSlider();
  },
  methods: {
    getSlider(){
        var url= '/web/slider-jl';
        axiosIns.get(url)
        .then(res => {
            this.items = res.data;
            // this.$nextTick(() => {
            //   this.carousel.refresh();
            // });
        })
        .catch(err =>{
            console.log(err);
        });
    },
    isExterno(url){
        if(url.indexOf('http') == 0)
            return true;
        else    
            return false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>