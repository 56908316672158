<template>
    <section v-if="items" class="accesos">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-md-12 text-center pb-4">
            <h1 class="titulo-seccion">Enlaces de Inter&eacute;s</h1>
          </div>
        </div>
        <div class="row" data-aos="fade-up" v-if="items.menuitems.length > 0">
          <carousel :autoplay="true" :nav="false" :responsive="{0:{items:2,nav:false}, 600:{items:3,nav:false}, 900:{items:4,nav:false}, 1200:{items:5,nav:false}}">
            <div class="box-enlace" v-for="item in items.menuitems" :key="item.id"> 
              <a :href="item.menuitemable.url" target="_blank">
              <div class="box-img text-center">
                  <img v-lazy="item.menuitemable.imagen" class="img-fluid" :alt="item.titulo" :title="item.titulo">
              </div>
              <div class="box-text text-center mt-3">
                {{item.titulo}}
              </div>
              </a>
            </div>
          </carousel>
        </div>
      </div>
    </section>
</template>

<script>
import axiosIns from '@/libs/axios';
import carousel from  'vue-owl-carousel';

export default {
  name: 'AccesosEnlaces',
  components:{
    carousel,
  },
  data(){
        return{
              items:{
                menuitems: [],
              },
        }
  },
  mounted(){          
      this.listar();
  },
  methods: {
    listar(){
        var url= '/web/enlaces/enlaces';
        axiosIns.get(url)
        .then(res => {
            this.items = res.data;
        })
        .catch(err =>{
            console.log(err);
        });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .box-enlace{
    margin: 0 10px;
    max-width: 200px;
    text-align: center;    
  }
  .box-img{
    text-align: center !important;
  }
  .box-img img{
    width: 40% !important;
    height: auto;
    margin: 0 auto;
  }
  .box-text{
    text-transform: uppercase;
    font-size: 12px;
  }
</style>
