<template>
    <section class="noticias">
      <div class="container">
        <div class="row">
          <div class="pb-4 text-center col-md-12">
            <h1 class="titulo-seccion">Últimas Noticias</h1>
          </div>
        </div>
        <div class="row" v-if="items.length >= 3">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div class="box-noticia-big" data-aos="fade-right">
              <div class="portada md-round">
                <img v-lazy="items[0].imagen" class="img-fluid img-responsive" :alt="items[0].titulo" :title="items[0].titulo">                
                <div class="box-description">
                  <small class="date"><i class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z" fill="#666666"/>
                    </svg>
                  </i> {{ items[0].publicado }}</small>
                  <p><router-link :to="'noticia/' + items[0].url">{{ items[0].titulo }}</router-link></p>
                  <div class="item-descripcion">
                    <small v-html="items[0].descripcion"></small>                    
                  </div>      
                  <router-link class="btn btn-sm btn-fiscalia float-end" :to="'noticia/' + items[0].url">
                    Leer más 
                    <i class="icon">
                      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7008 8.16086L5.60348 0.178257C5.55668 0.117395 5.49696 0.083313 5.43402 0.083313H4.00571C3.88629 0.083313 3.83141 0.307284 3.92179 0.424138L9.57369 7.8249H0.129112C0.0581006 7.8249 0 7.91255 0 8.01966V9.48034C0 9.58746 0.0581006 9.6751 0.129112 9.6751H9.57207L3.92018 17.0759C3.8298 17.1952 3.88467 17.4167 4.0041 17.4167H5.48082C5.51149 17.4167 5.54215 17.3996 5.56475 17.368L11.7008 9.33914C11.7567 9.26585 11.8015 9.17534 11.8322 9.0737C11.8629 8.97207 11.8788 8.86169 11.8788 8.75C11.8788 8.63831 11.8629 8.52793 11.8322 8.4263C11.8015 8.32466 11.7567 8.23415 11.7008 8.16086Z" fill="white"/>
                      </svg>
                    </i>
                  </router-link>
                </div>
              </div>              
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12" >
                <div class="box-noticia-small" data-aos="fade-up">
                  <div class="portada md-round">
                    <img v-lazy="items[1].imagen" class="img-fluid img-responsive" :alt="items[1].titulo" :title="items[1].titulo">  
                    
                    <div class="box-description">
                      <small class="date"><i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z" fill="#666666"/>
                        </svg>
                      </i> {{ items[1].publicado }}</small>
                      <p class="mb-1"><router-link :to="'noticia/' + items[1].url">{{ items[1].titulo }}</router-link></p>
                      <!-- <div class="mb-3 item-descripcion">
                        <small v-html="items[1].descripcion"></small>
                      </div>                  -->
                      <router-link class="btn btn-sm btn-fiscalia float-end" :to="'noticia/' + items[1].url">
                        Leer más 
                        <i class="icon">
                          <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7008 8.16086L5.60348 0.178257C5.55668 0.117395 5.49696 0.083313 5.43402 0.083313H4.00571C3.88629 0.083313 3.83141 0.307284 3.92179 0.424138L9.57369 7.8249H0.129112C0.0581006 7.8249 0 7.91255 0 8.01966V9.48034C0 9.58746 0.0581006 9.6751 0.129112 9.6751H9.57207L3.92018 17.0759C3.8298 17.1952 3.88467 17.4167 4.0041 17.4167H5.48082C5.51149 17.4167 5.54215 17.3996 5.56475 17.368L11.7008 9.33914C11.7567 9.26585 11.8015 9.17534 11.8322 9.0737C11.8629 8.97207 11.8788 8.86169 11.8788 8.75C11.8788 8.63831 11.8629 8.52793 11.8322 8.4263C11.8015 8.32466 11.7567 8.23415 11.7008 8.16086Z" fill="white"/>
                          </svg>
                        </i>
                      </router-link>
                    </div>
                  </div>              
                </div>  
              </div>
              <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                <div class="box-noticia-small" data-aos="fade-up">
                  <div class="portada md-round">
                    <img v-lazy="items[2].imagen" class="img-fluid img-responsive" :alt="items[2].titulo" :title="items[2].titulo">  
                    
                    <div class="box-description">
                      <small class="date"><i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z" fill="#666666"/>
                        </svg>
                      </i> {{ items[2].publicado }}</small>
                      <p class="mb-1"><router-link :to="'noticia/' + items[2].url">{{ items[2].titulo }}</router-link></p>
                      <!-- <div class="mb-3 item-descripcion">
                        <small v-html="items[2].descripcion"></small>
                      </div>                     -->
                      <router-link class="btn btn-sm btn-fiscalia float-end" :to="'noticia/' + items[2].url">
                        Leer más 
                        <i class="icon">
                          <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7008 8.16086L5.60348 0.178257C5.55668 0.117395 5.49696 0.083313 5.43402 0.083313H4.00571C3.88629 0.083313 3.83141 0.307284 3.92179 0.424138L9.57369 7.8249H0.129112C0.0581006 7.8249 0 7.91255 0 8.01966V9.48034C0 9.58746 0.0581006 9.6751 0.129112 9.6751H9.57207L3.92018 17.0759C3.8298 17.1952 3.88467 17.4167 4.0041 17.4167H5.48082C5.51149 17.4167 5.54215 17.3996 5.56475 17.368L11.7008 9.33914C11.7567 9.26585 11.8015 9.17534 11.8322 9.0737C11.8629 8.97207 11.8788 8.86169 11.8788 8.75C11.8788 8.63831 11.8629 8.52793 11.8322 8.4263C11.8015 8.32466 11.7567 8.23415 11.7008 8.16086Z" fill="white"/>
                          </svg>
                        </i>
                      </router-link>
                    </div>
                  </div>              
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="py-4 text-center col-md-12">
            <router-link 
              class="btn btn-md btn-fiscalia-white"
              to="noticias"
            >
              <i class="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z"/>
              <path d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z"/>
              </svg></i> Ver m&aacute;s noticias
            </router-link>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import axiosIns from '@/libs/axios';
//import carousel from 'vue-owl-carousel';

export default {
  name: 'UltimasNoticias',
  components:{
    //carousel,
  },
  data(){
        return{
              items:[],
        }
  },
  mounted(){          
      this.listar();
  },
  methods: {
    listar(){
        var url= '/web/ultimas-noticias';
        axiosIns.get(url)
        .then(res => {
            this.items = res.data;            
        })
        .catch(err =>{
            console.log(err);
        });
    },
  }
}
</script>

<style scoped>
  
</style>

